export const generatePageNumbers = (totalPages, currentPage) => {
  if (totalPages <= 8) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  if (currentPage <= 8) {
    const nextPages = currentPage === 8 ? Math.min(12, totalPages) : 8;
    return [
      ...Array.from({ length: nextPages }, (_, i) => i + 1),
      "...",
      totalPages,
    ];
  }

  if (currentPage > 8 && currentPage < totalPages - 6) {
    return [
      1,
      "...",
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      "...",
      totalPages,
    ];
  }

  return [1, "...", ...Array.from({ length: 8 }, (_, i) => totalPages - 7 + i)];
};
